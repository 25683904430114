<template>
	<div>
		<Table 
    :table="table"
     @fetch-data="fetchData" 
     @delete="deleteUser" 
     @search-data="initialize"
     @edit="editUser"
     />
	</div>
</template>

<script>
import Table from "@/components/core/Table"
import network from '@/core/network'
export default {
	components: {
		Table
	},
	data() {
		return {
			table: {
				page: 1,
				itemsPerPage: 10,
				totalItems: 0,
				desserts: [],
        search: '',
				headers: [
					{
						text: 'Tên',
						value: 'name',
						sortable: false
					},
          {
            text: 'Tên Quản lý',
            value: 'manager_name',
            sortable: false
          },
					{
						text: 'Tiền',
						value: 'balance',
						sortable: false
					},
          {
            text: 'Mã mời',
            value: 'invite_code',
            sortable: false
          },
          {
            text: 'Lượt mời',
            value: 'invited_turn',
            sortable: false
          },
          {
            text: 'Tạo lúc',
            value: 'created_at',
            sortable: false
          },
          {
            text: 'Khu vực',
            value: 'area',
            sortable: false
          },
					{ text: 'Thao tác', value: 'actions', sortable: false, width: "100px" },
				],
			}
		}
	},
	mounted () {
    this.initialize();
  },
	methods: {
		fetchData(page) {
      this.page = page;
			this.initialize()
		},
		initialize (searchData) {
      this.search = searchData
      network.get('/users',{
        name: searchData ?? '',
        page: this.table.page,
        limit: 10
      }).then(res => {
        this.table.desserts = res.data.docs
        this.table.totalItems = res.data.totalDocs
        console.log(this.desserts);
      })
    },
    editUser(id) {
    this.$router.push(`/admin/user/${id}`)
    },
    deleteUser(id) {
      network.post('/users/deleteUser', 
      {id: id}
      ).then(res => {
        console.log(res);
        if (res.status == 200) {
          this.$toast.open({
            message: res.data.message,
            type:'success',
            position: 'top'
          });
          this.initialize();
        } else {
          this.$toast.open({
            message: res.data.message,
            type: 'error',
            position: 'top'
          });
        }
      }).catch(err => {
        this.$toast.open({
          message: err,
          type: 'error',
          position: 'top'
      });
    });
	}
}

}
</script>

<style></style>